<template>
    <SvgIcon width="22" height="20" viewBox="0 0 22 20" strokeColor>
        <path
            d="M19 12V15H22V17H19V20H17V17H14V15H17V12H19ZM19.008 0C19.556 0 20 0.445 20 0.993V10H18V2H2V15.999L12 6L15 9V11.829L12 8.829L4.827 16H12V18H0.992C0.728813 17.9997 0.476497 17.895 0.290489 17.7088C0.104482 17.5226 -1.33455e-07 17.2702 0 17.007V0.993C0.00183004 0.730378 0.1069 0.479017 0.292513 0.293218C0.478126 0.107418 0.72938 0.00209465 0.992 0H19.008ZM6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "PicAddIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
