<template>
    <div class="base-header__events">
        <router-link to="/">
            <EventIcon />
            <span
                class="badge rounded-pill bg-danger"
                v-if="activityStore.count > 0"
                >{{ activityStore.count }}</span
            >
        </router-link>
    </div>
</template>

<script>
import EventIcon from "@/components/Icons/EventIcon";
import { useActivityStore } from "@/store/Activity";
export default {
    name: "NotificationComponent",
    components: { EventIcon },
    setup() {
        const activityStore = useActivityStore();
        return { activityStore };
    },
};
</script>

<style scoped></style>
