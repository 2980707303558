/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from "axios";
import { useAuthStore } from "@/store/Auth";

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api",
    withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const store = useAuthStore();
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.authUser &&
            !store.isGuest
        ) {
            store.logout();
        }
        return Promise.reject(error);
    }
);
