<template>
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" strokeColor>
        <path
            d="M9.19836 12.1897V14.1121C8.36584
    13.8178 7.47484 13.7275 6.6002 13.8489C5.72555 13.9702 4.89279 14.2997
    4.17185 14.8096C3.45092 15.3194 2.86285 15.9949 2.45703 16.7791C2.05122
    17.5634 1.8395 18.4335 1.83967 19.3166L2.37738e-07 19.3156C-0.000285254
    18.1924 0.256562 17.084 0.750861 16.0754C1.24516 15.0668 1.96379 14.1847
    2.85169 13.4968C3.73959 12.8088 4.77319 12.3333 5.8733 12.1066C6.9734
    11.8798 8.1108 11.9079 9.19836 12.1888V12.1897ZM7.35869 11.038C4.30943
    11.038 1.83967 8.56828 1.83967 5.51902C1.83967 2.46976 4.30943 0 7.35869
    0C10.4079 0 12.8777 2.46976 12.8777 5.51902C12.8777 8.56828 10.4079 11.038
    7.35869 11.038ZM7.35869 9.19836C9.39153 9.19836 11.038 7.55186 11.038
    5.51902C11.038 3.48618 9.39153 1.83967 7.35869 1.83967C5.32585 1.83967
    3.67935 3.48618 3.67935 5.51902C3.67935 7.55186 5.32585 9.19836 7.35869
    9.19836ZM12.6873 17.3978L15.9389 14.1462L17.2405 15.4468L12.6873 20L9.43476
    16.7475L10.7363 15.4468L12.6864 17.3978H12.6873Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "OtkIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
