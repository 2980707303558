import axios from "axios";
// import store from "@/store";
import { useAuthStore } from "@/store/Auth";

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const store = useAuthStore();
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.authUser &&
            !store.isGuest
        ) {
            store.logout();
        }
        console.log(error, 'dispatch("auth/logout")');
        return Promise.reject(error);
    }
);

export default {
    async login(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login", payload);
    },
    async loginByToken(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/api/loginByToken", payload);
    },
    logout() {
        return authClient.post("/logout");
    },
    async forgotPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/forgot-password", payload);
    },
    async getAuthUser() {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.get("/api/users/auth");
    },
    async resetPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/reset-password", payload);
    },
    updatePassword(payload) {
        return authClient.put("/user/password", payload);
    },
    async registerUser(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/register", payload);
    },
    sendVerification(payload) {
        return authClient.post("/email/verification-notification", payload);
    },
    updateUser(payload) {
        return authClient.put("/user/profile-information", payload);
    },
};
