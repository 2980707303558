<template>
    <SvgIcon width="20" height="20" viewBox="0 0 11 6" strokeColor>
        <path
            d="M10.798 0.964285C10.798 0.883928
    10.7578 0.793526 10.6975 0.733259L10.1953 0.231026C10.135 0.170759 10.0446
    0.13058 9.96429 0.13058C9.88393 0.13058 9.79353 0.170759 9.73326
    0.231026L5.78571 4.17857L1.83817 0.231026C1.7779 0.170759 1.6875 0.13058
    1.60714 0.13058C1.51674 0.13058 1.43638 0.170759 1.37612 0.231026L0.873884
    0.733259C0.813616 0.793526 0.773438 0.883928 0.773438 0.964285C0.773438
    1.04464 0.813616 1.13504 0.873884 1.19531L5.55469 5.87612C5.61496 5.93638
    5.70536 5.97656 5.78571 5.97656C5.86607 5.97656 5.95647 5.93638 6.01674
    5.87612L10.6975 1.19531C10.7578 1.13504 10.798 1.04464 10.798 0.964285Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "ArrowDownIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
