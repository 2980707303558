import { useAuthStore } from "@/store/Auth";

export default {
    install: ({ config }) => {
        config.globalProperties.$auth = useAuthStore();
        if (useAuthStore().authUser) {
            useAuthStore().getAuthUser();
        }
    },
};
