<template>
    <LoginComponent />
</template>

<script>
import LoginComponent from "@/components/Login/LoginComponent";
export default {
    name: "LoginView",
    components: { LoginComponent },
};
</script>
