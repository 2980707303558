<template>
    <SvgIcon width="20" height="20" viewBox="0 0 24 24" strokeColor>
        <path
            d="M16 16V12L21 17L16 22V18H4V16H16ZM8 2V5.999L20 6V8H8V12L3 7L8 2Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "ReloadIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
