<template>
    <SvgIcon width="20" height="20" viewBox="0 0 24 24" strokeColor>
        <path
            d="M12.0015 8C12.8436 8.00032 13.6641 8.26638 14.3461 8.76027C15.0281 9.25417 15.5369 9.9507 15.7999 10.7506C16.0629 11.5506 16.0667 12.4131 15.8109 13.2154C15.555 14.0176 15.0525 14.7187 14.3749 15.2186C13.6974 15.7186 12.8793 15.992 12.0372 15.9998C11.1952 16.0077 10.3722 15.7496 9.6854 15.2623C8.99863 14.775 8.48315 14.0835 8.2124 13.2861C7.94165 12.4888 7.92944 11.6263 8.17752 10.8216C8.36465 11.2398 8.68974 11.581 9.09836 11.7881C9.50699 11.9953 9.97436 12.0558 10.4223 11.9596C10.8702 11.8633 11.2715 11.6162 11.5589 11.2595C11.8464 10.9028 12.0027 10.4581 12.0015 10C12.0016 9.61477 11.8905 9.23771 11.6814 8.91414C11.4724 8.59057 11.1743 8.33425 10.8231 8.176C11.1959 8.0616 11.5911 8 12.0015 8Z"
            stroke="none"
        />
        <path
            d="M12 3C17.9806 3 22.9563 6.88 24 12C22.9574 17.12 17.9806 21 12 21C6.01941 21 1.04372 17.12 0 12C1.04261 6.88 6.01941 3 12 3ZM12 19C14.2621 18.9996 16.457 18.3068 18.2256 17.0352C19.9941 15.7635 21.2314 13.9883 21.7351 12C21.2296 10.0133 19.9914 8.24 18.2231 6.97003C16.4547 5.70005 14.2608 5.00853 12 5.00853C9.7392 5.00853 7.54527 5.70005 5.77692 6.97003C4.00857 8.24 2.77042 10.0133 2.2649 12C2.76857 13.9883 4.00592 15.7635 5.77445 17.0352C7.54297 18.3068 9.7379 18.9996 12 19Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "ViewIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
