<template>
    <SvgIcon width="20" height="20" viewBox="0 0 18 18" strokeColor>
        <path
            d="M12.728 6.65679L11.314 5.24279L2 14.5568V15.9708H3.414L12.728 6.65679ZM14.142 5.24279L15.556 3.82879L14.142 2.41479L12.728 3.82879L14.142 5.24279ZM4.242 17.9708H0V13.7278L13.435 0.292787C13.6225 0.105316 13.8768 0 14.142 0C14.4072 0 14.6615 0.105316 14.849 0.292787L17.678 3.12179C17.8655 3.30931 17.9708 3.56362 17.9708 3.82879C17.9708 4.09395 17.8655 4.34826 17.678 4.53579L4.243 17.9708H4.242Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "EditIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
