import { defineStore } from "pinia";
import SearchService from "@/services/SearchService";

export const useSearchStore = defineStore({
    id: "search",
    state: () => ({
        search: null,
        loading: false,
        error: null,
        count: 0,
    }),
    getters: {
        getResults: (state) => state.search,
    },
    actions: {
        async getSearchResults(query) {
            this.search = null;
            this.loading = true;
            this.count = 0;
            try {
                const response = await SearchService.getSearchResults({
                    query: query,
                });
                this.search = response.data.data;
                this.count = response.data.data.length;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    },
});
