<template>
    <SvgIcon width="20" height="20" viewBox="0 0 27 30" strokeColor>
        <path
            d="M13.1287 30C11.2287 30 9.72873 28.7 9.22873 27H2.12873C1.32873 27 0.728725 26.7 0.328725 26.1C-0.171275 25.3 -0.0712747 24.3 0.428725 23.2C0.528725 23.1 0.528725 23 0.628725 22.9L3.12873 20.6V12C3.12873 7.8 5.72872 4.1 9.62873 2.6C9.82872 1.1 11.3287 0 13.1287 0C14.9287 0 16.4287 1.1 16.6287 2.6C20.5287 4.1 23.1287 7.8 23.1287 12V20.6L25.6287 22.9C25.7287 23 25.8287 23.1 25.8287 23.2C26.3287 24.3 26.4287 25.3 25.9287 26.1C25.5287 26.7 24.9287 27 24.1287 27H17.0287C16.5287 28.7 15.0287 30 13.1287 30ZM11.4287 27C11.7287 27.6 12.4287 28 13.1287 28C13.8287 28 14.5287 27.6 14.8287 27H11.4287ZM24.2287 25C24.2287 25 24.3287 24.7 24.1287 24.2L21.4287 21.7C21.2287 21.5 21.1287 21.3 21.1287 21V12C21.1287 8.4 18.7287 5.3 15.3287 4.3C14.8287 4.2 14.5287 3.6 14.6287 3.1V3C14.6287 2.5 13.9287 2 13.1287 2C12.3287 2 11.6287 2.5 11.6287 3V3.1C11.7287 3.6 11.4287 4.1 10.9287 4.3C7.52873 5.3 5.12873 8.4 5.12873 12V21C5.12873 21.3 5.02873 21.5 4.82873 21.7L2.12873 24.2C1.92873 24.6 1.92873 24.9 2.02873 25H24.2287Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "EventIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
