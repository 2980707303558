import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import auth from "./plugins/auth";
import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
const app = createApp(App);
app.use(createPinia()).use(auth).use(router).use(BootstrapVue3).mount("#app");
