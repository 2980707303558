import * as API from "@/services/API";

export default {
    getList() {
        return API.apiClient.get(`/activity/`);
    },
    delete(id) {
        return API.apiClient.delete(`/activity/${id}`);
    },
};
