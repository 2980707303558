<template>
    <div class="base-header__user">
        {{ userName }}
        <div
            class="base-header__user__avatar"
            :style="{ 'background-image': 'url(' + userPic + ')' }"
        />
    </div>
</template>

<script>
export default {
    name: "UserCardComponent",
    props: {
        userData: Object,
    },
    data() {
        return {};
    },
    computed: {
        userName() {
            return this.userData !== null ? this.userData.short_name : "";
        },
        userPic() {
            return this.userData !== null ? this.userData.avatar : "";
        },
    },
};
</script>

<style scoped></style>
