<template>
    <SvgIcon width="20" height="20" viewBox="0 0 14 12" strokeColor>
        <path
            d="M6.64227 6.34216L9.11736 8.81666L8.29194 9.64208L7.22561 8.57574V11.8337H6.05894V8.57458L4.99261 9.64208L4.16719 8.81666L6.64227 6.34216ZM6.64227 0.166992C7.64384 0.16704 8.61048 0.535142 9.35839 1.20131C10.1063 1.86748 10.5833 2.78526 10.6988 3.78016C11.4246 3.97809 12.0578 4.4248 12.4877 5.04222C12.9175 5.65963 13.1168 6.40848 13.0505 7.15789C12.9843 7.90729 12.6568 8.6096 12.1253 9.14205C11.5939 9.67451 10.8921 10.0032 10.1429 10.0708V8.89599C10.4113 8.85766 10.6694 8.76623 10.9021 8.62706C11.1348 8.48789 11.3375 8.30375 11.4983 8.0854C11.659 7.86705 11.7747 7.61886 11.8385 7.35532C11.9023 7.09178 11.913 6.81816 11.8699 6.55045C11.8269 6.28274 11.7309 6.02629 11.5876 5.79607C11.4443 5.56586 11.2566 5.36649 11.0355 5.2096C10.8143 5.05271 10.5641 4.94145 10.2995 4.88232C10.0349 4.82318 9.7611 4.81734 9.49419 4.86516C9.58554 4.43984 9.58061 3.99948 9.47973 3.57631C9.37886 3.15315 9.18461 2.75792 8.9112 2.41955C8.6378 2.08119 8.29217 1.80826 7.89963 1.62077C7.50709 1.43328 7.07758 1.33597 6.64256 1.33597C6.20755 1.33597 5.77804 1.43328 5.3855 1.62077C4.99296 1.80826 4.64733 2.08119 4.37392 2.41955C4.10052 2.75792 3.90627 3.15315 3.8054 3.57631C3.70452 3.99948 3.69958 4.43984 3.79094 4.86516C3.25874 4.76522 2.70863 4.88078 2.26164 5.18644C1.81465 5.49209 1.50738 5.96279 1.40744 6.49499C1.3075 7.02719 1.42306 7.5773 1.72872 8.02429C2.03437 8.47128 2.50507 8.77855 3.03727 8.87849L3.14227 8.89599V10.0708C2.39295 10.0034 1.69118 9.67468 1.1596 9.14227C0.628025 8.60986 0.300463 7.90757 0.234165 7.15814C0.167868 6.40871 0.367052 5.65982 0.796904 5.04236C1.22676 4.4249 1.85993 3.97813 2.58577 3.78016C2.7011 2.78521 3.17809 1.86736 3.92603 1.20116C4.67396 0.53497 5.64066 0.166912 6.64227 0.166992V0.166992Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "UploadIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
