<template>
    <slot />
</template>

<script>
export default {
    name: "LoginLayout",
    components: {},
};
</script>

<style scoped></style>
