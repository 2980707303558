<template>
    <SvgIcon width="20" height="20" viewBox="0 0 24 25" strokeColor>
        <path
            d="M4 19.8747H20V14.8747H22V20.8747C22
    21.14 21.8946 21.3943 21.7071 21.5819C21.5196 21.7694 21.2652 21.8747 21
    21.8747H3C2.73478 21.8747 2.48043 21.7694 2.29289 21.5819C2.10536 21.3943 2
    21.14 2 20.8747V14.8747H4V19.8747ZM16.172 7.87474L12.222 3.92474L13.636
    2.51074L20 8.87474L13.636 15.2387L12.222 13.8247L16.172
    9.87474H5V7.87474H16.172Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "LogoutIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
