<template>
    <div class="base">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-auto">
                    <div class="base-lside">
                        <SidebarMenu @run="showHomer = true" />
                    </div>
                </div>
                <div class="col">
                    <div class="base-layout">
                        <HeaderComponent />
                        <div class="base-content">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Transition name="homer" @after-enter="homerRunEnd">
        <div class="easter-egg" v-if="showHomer">
            <img src="@/assets/homer.gif" alt="homer running" height="250" />
        </div>
    </Transition>
</template>

<script>
import HeaderComponent from "@/components/Header/HeaderComponent";
import SidebarMenu from "@/components/Menu/SidebarMenu";
export default {
    name: "DefaultLayout",
    components: { SidebarMenu, HeaderComponent },
    data() {
        return {
            showHomer: false,
        };
    },
    mounted() {},
    methods: {
        homerRunEnd() {
            this.showHomer = false;
        },
    },
};
</script>

<style scoped lang="scss">
.homer-enter-active {
    transition: all 7s linear;
}
.homer-enter-from {
}
.homer-enter-to {
    transform: translateX(120vw);
}
.easter-egg {
    position: fixed;
    bottom: 0;
    left: -500px;

    width: 100%;
    display: inline-block;
    margin-right: 10px;
    text-align: left;
    & img {
        position: relative;
        right: -200px;
    }
}
</style>
