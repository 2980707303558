<template>
    <SvgIcon width="20" height="20" viewBox="0 0 512 512" strokeColor>
        <path
            d="m288.134 128.533h-80.334c-8.284 0-15 6.716-15 15v113.533h-17.133c-8.284 0-15 6.716-15 15s6.716 15 15 15h17.133v34.267h-17.133c-8.284 0-15 6.716-15 15s6.716 15 15 15h17.133v33.2c0 8.284 6.716 15 15 15s15-6.716 15-15v-33.2h65.334c8.284 0 15-6.716 15-15s-6.716-15-15-15h-65.334v-34.267h65.334c43.708 0 79.267-35.559 79.267-79.267-.001-44.788-36.484-79.266-79.267-79.266zm0 128.533h-65.334v-98.533h65.334c27.002 0 49.267 21.78 49.267 49.267-.001 27.166-22.101 49.266-49.267 49.266z"
        />
        <path
            d="m256 0c-141.159 0-256 114.841-256 256s114.841 256 256 256 256-114.841 256-256-114.841-256-256-256zm0 482c-124.617 0-226-101.383-226-226s101.383-226 226-226 226 101.383 226 226-101.383 226-226 226z"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "ManagersIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
