<template>
    <svg
        :class="iconClassObject"
        focusable="false"
        :viewBox="viewBox"
        :width="width"
        :height="height"
        aria-hidden="true"
        role="presentation"
    >
        <slot></slot>
    </svg>
</template>

<script>
export default {
    name: "SvgIcon",
    props: {
        className: String,
        style: Object,
        top: Boolean,
        middle: Boolean,
        bottom: Boolean,
        width: { type: [String, Number], default: 20 },
        /** Высота */
        height: { type: [String, Number], default: 20 },
        /** Размер иконки
         *  - lxs: 18px
         *  - xs: 20px
         *  - sm: 30px
         *  - md: 40px
         *  - lg: 50px
         *  - exlg: 60px
         */
        size: { type: [String, Number], default: "xs" },
        /** ViewBox */
        viewBox: { type: String, default: "0 0 20 20" },
        /** Цвет обводки цвета текста */
        strokeColor: Boolean,
        /** Цвет заливки цвета текста */
        fillColor: Boolean,
    },
    data() {
        return {
            sizeArray: ["lxs", "xs", "sm", "md", "lg", "exlg"],
            iconClass: this.className,
            fillColorValue: true,
        };
    },

    computed: {
        iconClassObject: function () {
            return {
                [`svg-icon_${this.size}`]:
                    this.sizeArray.includes(String(this.size)) && this.size,
                "svg-icon_top": this.top,
                "svg-icon_middle": this.middle,
                "svg-icon_bottom": this.bottom,
                "svg-icon_stroke-cc": this.strokeColor,
                "svg-icon_fill-cc": this.fillColorValue,
                "svg-icon": true,
            };
        },
    },
};
</script>

<style scoped></style>
