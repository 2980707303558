<template>
    <div class="title__suptitle">
        <div class="title__return-link">
            <a
                class="return-link"
                v-if="!!backLink"
                href="#"
                @click.prevent="$router.back()"
                >{{ backLink.title }}&nbsp;&nbsp;<ArrowReturnIcon />
            </a>
        </div>
        <div class="title__date" v-if="!!date && !!author">
            <span v-if="!!author">{{ author.short_name }} <br /></span>
            <span v-if="!!date">{{ date }}</span>
        </div>
    </div>
    <h1 class="title">
        {{ title }}
        <span
            class="title__badge badge rounded-pill"
            :class="badgeBg"
            v-if="!!titleBadge"
            >{{ titleBadge.text }}</span
        >
    </h1>
</template>

<script>
import ArrowReturnIcon from "@/components/Icons/ArrowReturnIcon";
export default {
    name: "PageTitle",
    components: { ArrowReturnIcon },
    props: {
        title: String,
        backLink: Object,
        titleBadge: Object,
        date: String,
        author: Object,
    },
    data() {
        return {
            badgeBg:
                typeof this.titleBadge !== "undefined" && this.titleBadge.type
                    ? "bg-" + this.titleBadge.type
                    : "",
        };
    },
};
</script>

<style scoped lang="scss">
.title {
    margin-bottom: 30px;

    &__badge {
        padding: 3px 9px;

        font-size: 16px;
        line-height: 19px;

        vertical-align: middle;
    }

    &__return-link {
        height: 14px;
        margin-bottom: 8px;
    }

    &__suptitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__date {
        font-size: 14px;
        line-height: 16px;

        color: $gray-600;
        margin-bottom: 8px;
    }
}
</style>
