import { defineStore } from "pinia";
import AuthService from "@/services/AuthService";
import { getError } from "@/utils/helpers";
import router from "@/router";

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        user: null,
        loading: false,
        error: null,
    }),
    getters: {
        authUser: (state) => state.user,
        isAdmin: (state) => (state.user ? state.user.is_admin : false),
        isSenior: (state) => (state.user ? state.user.is_senior : false),
        getError: (state) => state.error,
        isLoading: (state) => state.loading,
        userGroup: (state) => state.user.group,
        isLoggedIn: (state) => !!state.user,
        isGuest: () => {
            const storageItem = window.localStorage.getItem("guest");
            if (!storageItem) return false;
            if (storageItem === "isGuest") return true;
            if (storageItem === "isNotGuest") return true;
        },
        userId: (state) => (state.user ? state.user.id : false),
    },
    actions: {
        logout() {
            return AuthService.logout().then(() => {
                this.user = null;
                if (router.currentRoute.name !== "login")
                    router.push({ path: "/login" });
            });
        },
        setGuest(value) {
            window.localStorage.setItem("guest", value);
        },
        async getAuthUser() {
            this.loading = true;
            try {
                const response = await AuthService.getAuthUser();
                this.user = response.data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.user = null;
                this.error = getError(error);
            }
        },
    },
});
