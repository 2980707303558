<template>
    <SvgIcon width="20" height="20" viewBox="0 0 16 16" strokeColor>
        <path
            d="M5.33398 4.6665V7.33317L1.33398 3.99984L5.33398 0.666504V3.33317H8.66732C10.0818 3.33317 11.4384 3.89507 12.4386 4.89527C13.4387 5.89546 14.0007 7.25202 14.0007 8.6665C14.0007 10.081 13.4387 11.4375 12.4386 12.4377C11.4384 13.4379 10.0818 13.9998 8.66732 13.9998H2.66732V12.6665H8.66732C9.72818 12.6665 10.7456 12.2451 11.4957 11.4949C12.2459 10.7448 12.6673 9.72737 12.6673 8.6665C12.6673 7.60564 12.2459 6.58822 11.4957 5.83808C10.7456 5.08793 9.72818 4.6665 8.66732 4.6665H5.33398Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "ArrowReturnIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
