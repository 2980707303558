<template>
    <ul class="nav">
        <li class="nav-item" v-for="(item, index) in items" :key="index">
            <RouterLink
                :to="'/' + root + '/' + item.routeName + '/'"
                active-class="active"
                class="nav-link"
            >
                {{ item.text }}
            </RouterLink>
        </li>
    </ul>
</template>

<script>
export default {
    name: "InnerMenu",
    data() {
        return {
            root: this.rootPath,
            items: [
                {
                    text: "Регламенты",
                    routeName: "regulations",
                },
                {
                    text: "Обучение",
                    routeName: "education",
                },
                {
                    text: "Справочник",
                    routeName: "handbook",
                    showOnlyFor: ["managers"],
                },
                {
                    text: "Тестирование",
                    routeName: "testing",
                },
                {
                    text: "Вопросы и ответы",
                    routeName: "faq",
                },
            ],
        };
    },
    props: {
        rootPath: String,
    },
    created() {
        this.items = this.items.filter((item) => {
            return (
                typeof item.showOnlyFor === "undefined" ||
                (typeof item.showOnlyFor !== "undefined" &&
                    item.showOnlyFor.includes(this.root))
            );
        });
    },
};
</script>

<style scoped lang="scss">
.nav {
    margin-top: 32px;
    margin-bottom: 26px;

    &-link {
        margin-right: 36px;

        font-weight: 700;
        letter-spacing: 0.08em;

        text-transform: uppercase;

        &.active {
            color: $primary;
            border-bottom: 2px solid $primary;
        }
    }
}
</style>
