<template>
    <div class="base-header">
        <SearchComponent />
        <UserCardComponent :user-data="user" />
        <NotificationComponent />
    </div>
</template>

<script>
import SearchComponent from "@/components/Header/Search/SearchComponent";
import UserCardComponent from "@/components/Header/UserCard/UserCardComponent";
import NotificationComponent from "@/components/Header/Notification/NotificationComponent";
import { useAuthStore } from "@/store/Auth";
export default {
    name: "HeaderComponent",
    components: { NotificationComponent, UserCardComponent, SearchComponent },

    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },
    data() {
        return {};
    },
    computed: {
        user() {
            return this.authStore.authUser;
        },
    },
};
</script>

<style scoped></style>
