<template>
    <div class="error-page">
        <PageTitle title="Запрашиваемая страница не найдена..." />
        <div class="error-page__content">
            <div class="error-page__content__pic">
                <img :src="error404" alt="" />
            </div>
            <div class="error-page__content__text">
                <p>
                    Мы обязательно разберемся с этим недоразумением, а пока
                    вернитесь на главную
                </p>
                <RouterLink to="/">
                    <button type="button" class="btn btn-primary btn-lg">
                        Вернуться на главную
                    </button>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from "@/components/Custom/PageTitle";
import error404 from "@/assets/img/error404.svg";
export default {
    name: "NotFoundView",
    components: { PageTitle },
    data() {
        return {
            error404: error404,
        };
    },
};
</script>

<style scoped></style>
