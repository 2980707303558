<template>
    <SvgIcon width="20" height="20" viewBox="0 0 14 14" strokeColor>
        <path d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z" stroke="none" />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "PlusIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
