<template>
    <div>
        <PageTitle title="Автор24" />
        <InnerMenu root-path="author24" />
        <router-view></router-view>
    </div>
</template>

<script>
import InnerMenu from "@/components/Menu/InnerMenu";
import PageTitle from "@/components/Custom/PageTitle";
export default {
    name: "Author24View",
    components: { PageTitle, InnerMenu },
    data() {
        return {
            route: this.$route.path,
        };
    },
};
</script>

<style scoped></style>
