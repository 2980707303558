<template>
    <component :is="layout">
        <router-view v-slot="{ Component }">
            <transition name="route" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    </component>
</template>

<style>
.route-enter-active,
.route-leave-active,
.layout-enter-active,
.layout-leave-active {
    transition: opacity 0.1s ease;
}
.route-enter-from,
.route-leave-to,
.layout-enter-from,
.layout-leave-to {
    opacity: 0;
}
</style>
<script>
const defaultLayout = "DefaultLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import LoginLayout from "@/layouts/LoginLayout";
export default {
    components: {
        DefaultLayout,
        LoginLayout,
    },
    data() {
        return {
            token: false,
        };
    },
    computed: {
        layout() {
            return this.$route.meta.layout || defaultLayout;
        },
    },
    mounted() {
        let getQuiz = localStorage.getItem("quiz");
        if (getQuiz && this.$route.fullPath !== getQuiz) {
            this.$router.push(getQuiz);
        }
    },
};
</script>
