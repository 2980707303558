<template>
    <SvgIcon width="20" height="20" viewBox="0 0 13 13" strokeColor>
        <path
            d="M6.364 4.95L11.314 0L12.728 1.414L7.778 6.364L12.728 11.314L11.314 12.728L6.364 7.778L1.414 12.728L0 11.314L4.95 6.364L0 1.414L1.414 0L6.364 4.95Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "CloseIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
