import { defineStore } from "pinia";
import ActivityService from "@/services/ActivityService";

export const useActivityStore = defineStore({
    id: "activity",
    state: () => ({
        activity: [],
        loading: false,
        error: null,
    }),
    getters: {
        getActivityList: (state) => state.activity,
        count: (state) => state.activity.length,
    },
    actions: {
        async getList() {
            this.activity = [];
            this.loading = true;
            try {
                const response = await ActivityService.getList();
                this.activity = response.data.data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        async delete(index) {
            try {
                await ActivityService.delete(this.activity[index].id);
                this.activity.splice(index, 1);
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    },
});
