<template>
    <SvgIcon width="20" height="20" viewBox="0 0 24 24" strokeColor>
        <path
            d="M12.9999 13V18.585L14.8279 16.757L16.2429 18.172L11.9999 22.414L7.75689 18.172L9.17189 16.757L10.9999 18.585V13H12.9999ZM11.9999 2C13.7169 2.00008 15.374 2.63111 16.6561 3.77312C17.9382 4.91512 18.756 6.48846 18.9539 8.194C20.1982 8.53332 21.2836 9.2991 22.0206 10.3575C22.7575 11.416 23.099 12.6997 22.9855 13.9844C22.872 15.2691 22.3106 16.473 21.3995 17.3858C20.4883 18.2986 19.2854 18.8622 18.0009 18.978V16.964C18.4611 16.8983 18.9035 16.7416 19.3025 16.503C19.7014 16.2644 20.0488 15.9487 20.3245 15.5744C20.6001 15.2001 20.7984 14.7746 20.9078 14.3228C21.0172 13.8711 21.0355 13.402 20.9616 12.9431C20.8878 12.4841 20.7232 12.0445 20.4776 11.6498C20.232 11.2552 19.9102 10.9134 19.5311 10.6445C19.152 10.3755 18.7231 10.1848 18.2694 10.0834C17.8158 9.98203 17.3465 9.97203 16.8889 10.054C17.0455 9.32489 17.037 8.56997 16.8641 7.84455C16.6912 7.11913 16.3582 6.44158 15.8895 5.86153C15.4208 5.28147 14.8283 4.81361 14.1554 4.49219C13.4824 4.17078 12.7461 4.00397 12.0004 4.00397C11.2546 4.00397 10.5183 4.17078 9.84542 4.49219C9.1725 4.81361 8.57999 5.28147 8.1113 5.86153C7.64261 6.44158 7.3096 7.11913 7.13668 7.84455C6.96375 8.56997 6.95528 9.32489 7.11189 10.054C6.19955 9.88267 5.25651 10.0808 4.49024 10.6048C3.72396 11.1287 3.19722 11.9357 3.02589 12.848C2.85456 13.7603 3.05268 14.7034 3.57666 15.4697C4.10063 16.2359 4.90755 16.7627 5.81989 16.934L5.99989 16.964V18.978C4.71534 18.8623 3.5123 18.2989 2.60103 17.3862C1.68976 16.4735 1.12822 15.2696 1.01457 13.9848C0.900915 12.7001 1.24237 11.4163 1.97926 10.3578C2.71615 9.29926 3.8016 8.53339 5.04589 8.194C5.2436 6.48838 6.0613 4.91491 7.34347 3.77287C8.62565 2.63082 10.2829 1.99986 11.9999 2Z"
            stroke="none"
        />
    </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/Icons/SvgIcon";
export default {
    name: "DownloadIcon",
    components: { SvgIcon },
};
</script>

<style scoped></style>
