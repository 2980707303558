<template>
    <div class="auth-layout">
        <div class="container-fluid auth-layout-container">
            <div class="row">
                <div class="col col-md-6 col-xl-7">
                    <div class="auth-layout__company">
                        <img
                            :src="logo"
                            class="auth-layout__company__logo"
                            alt=""
                        />
                        <p>
                            Продуктивность – не случайность. Это всегда
                            результат стремления к совершенству,<br />разумного
                            планирования и целенаправленных усилий.
                        </p>
                    </div>
                </div>
                <div class="col col-md-6 col-xl-5 auth-form-container">
                    <div class="auth-form-content">
                        <h2 class="mb-4">Авторизация</h2>
                        <div class="error">{{ error }}</div>
                        <form novalidate="" class="" @submit.prevent="login">
                            <div class="mb-4 form-floating">
                                <input
                                    placeholder="E-mail"
                                    required
                                    type="email"
                                    v-model="email"
                                    id="formBasicEmail"
                                    class="form-control form-control-lg"
                                />
                                <div class="invalid-feedback">
                                    Неверный формат эл.почты
                                </div>
                                <label for="formBasicEmail">E-mail</label>
                            </div>
                            <div class="mb-4 form-floating">
                                <input
                                    placeholder="Пароль"
                                    v-model="password"
                                    aria-describedby="passwordHelpBlock"
                                    type="password"
                                    required
                                    id="formBasicPassword"
                                    class="form-control form-control-lg"
                                />
                                <div class="invalid-feedback">
                                    Минимум 8 символов
                                </div>
                                <label for="formBasicPassword">Пароль</label>
                            </div>
                            <div class="justify-content-between row">
                                <div class="col">
                                    <button
                                        type="submit"
                                        class="btn btn-primary btn-lg"
                                    >
                                        Войти
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import { useAuthStore } from "@/store/Auth";
import { getError } from "@/utils/helpers";
import logo from "@/assets/auth/logo.svg";

export default {
    name: "LoginComponent",
    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },
    data() {
        return {
            email: "",
            password: "",
            error: null,
            logo: logo,
        };
    },
    computed: {},
    methods: {
        async loginByToken(token) {
            localStorage.removeItem("quiz");
            this.error = null;
            try {
                await AuthService.loginByToken({ token_name: token });
                await this.authStore.getAuthUser();
                const authUser = this.authStore.authUser;
                if (authUser) {
                    this.authStore.setGuest("isNotGuest");
                    this.$router.push("/");
                } else {
                    const error = Error(
                        "Невозможно получить пользователя после авторизации. Check Api"
                    );
                    error.name = "Fetch user";
                    throw error;
                }
            } catch (error) {
                this.error = getError(error);
            }
        },
        async login() {
            const payload = {
                email: this.email,
                password: this.password,
            };
            this.error = null;
            try {
                await AuthService.login(payload);
                await this.authStore.getAuthUser();
                const authUser = this.authStore.authUser;
                if (authUser) {
                    this.authStore.setGuest("isNotGuest");
                    this.$router.push("/");
                } else {
                    const error = Error(
                        "Невозможно получить пользователя после авторизации. Check Api"
                    );
                    error.name = "Fetch user";
                    throw error;
                }
            } catch (error) {
                this.error = getError(error);
            }
        },
    },
    mounted() {
        if (typeof this.$route.query.auth !== "undefined") {
            this.loginByToken(this.$route.query.auth);
        }
    },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.auth-layout {
    position: relative;

    height: auto;
    min-height: 100vh;

    color: #fff;
    background: #0087cf url("@/assets/auth/auth-bg.jpg") repeat-x 0 100%;

    &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 100%;

        z-index: 1;

        background: url("@/assets/auth/auth-bg.svg") no-repeat 0 100%;
    }

    &-container {
        position: relative;

        z-index: 10;
    }

    &__company {
        font-size: 14px;
        line-height: 25px;

        @include media-breakpoint-up(lg) {
            margin: 253px 10%;
        }

        &__logo {
            display: block;
            max-width: 100%;
            margin-bottom: 14px;
        }
    }
}

.auth-form-container {
    background: #fff;
    color: #373d59;
    min-height: 100vh;

    display: flex;
}

.auth-form-content {
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(lg) {
        padding: 253px 100px;
    }
}
</style>
