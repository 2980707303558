<template>
    <div class="base-menu">
        <div class="base-menu__header" @click="clickCounter">
            <img :src="logo" class="base-menu__header__logo" alt="" />
        </div>
        <div class="base-menu__list">
            <RouterLink
                to="/managers"
                :class="{ current: subIsActive('/managers') }"
                class="base-menu__list__link"
            >
                <ManagersIcon /> Менеджеры
            </RouterLink>
            <RouterLink
                to="/author24"
                :class="{ current: subIsActive('/author24') }"
                class="base-menu__list__link"
            >
                <ManagersIcon /> Автор24
            </RouterLink>
            <RouterLink
                to="/curators"
                :class="{ current: subIsActive('/curators') }"
                class="base-menu__list__link"
            >
                <CuratorsIcon /> Кураторы
            </RouterLink>
            <RouterLink
                to="/otk"
                :class="{ current: subIsActive('/otk') }"
                class="base-menu__list__link"
            >
                <OtkIcon /> ОТК
            </RouterLink>
            <RouterLink
                to="/marketing"
                :class="{ current: subIsActive('/marketing') }"
                class="base-menu__list__link"
            >
                <MarketingIcon /> Маркетологи
            </RouterLink>
            <RouterLink
                to="/news"
                :class="{ current: subIsActive('/news') }"
                class="base-menu__list__link"
            >
                <NewsIcon /> Новости
            </RouterLink>
        </div>
        <div class="base-menu__logout">
            <button
                type="button"
                href="#"
                class="base-menu__logout__btn btn btn-light"
                @click="logoutUser"
            >
                <LogoutIcon /> Выход
            </button>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/img/logo.png";
import {
    LogoutIcon,
    NewsIcon,
    OtkIcon,
    CuratorsIcon,
    ManagersIcon,
    MarketingIcon,
} from "@/components/Icons/";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/Auth";

export default {
    name: "SidebarMenu",
    components: {
        LogoutIcon,
        NewsIcon,
        OtkIcon,
        CuratorsIcon,
        ManagersIcon,
        MarketingIcon,
    },
    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },
    data() {
        return {
            logo: logo,
            items: {},
            logoClicks: 0,
        };
    },
    mounted() {},
    computed: {
        activeRouteFullPath() {
            return useRouter().currentRoute.value.fullPath;
        },
    },
    methods: {
        clickCounter() {
            this.logoClicks++;
            if (this.logoClicks > 5) {
                this.$emit("run");
                this.logoClicks = 0;
            }
        },
        logoutUser() {
            this.authStore.logout();
        },
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some((path) => {
                return this.activeRouteFullPath.indexOf(path) === 0; // current path starts with this path string
            });
        },
    },
};
</script>

<style lang="scss">
.base-menu {
    width: 220px;

    &__header {
        display: flex;
        justify-content: center;

        padding: 20px 0;

        border-bottom: 1px solid #27b4ff;

        &__logo {
            display: block;

            max-width: 100%;
        }
    }

    &__list {
        padding-top: 15px;
        padding-bottom: 20px;

        border-bottom: 1px solid #27b4ff;

        &__link {
            display: block;

            padding: 18px 25px;

            font-size: 18px;
            line-height: 21px;
            text-decoration: none;
            color: $white;

            & .svg-icon {
                margin-right: 25px;

                font-size: 22px;

                fill: $white;
            }

            &:hover {
                color: $white;
                border-radius: 50em;
                background-color: rgba($white, 0.1);
            }

            &.current {
                position: relative;

                color: $blacktext;
                border-radius: 50em 0 0 50em;
                background-color: $white;

                margin-right: -25px;

                & .svg-icon {
                    fill: $blue;
                }

                &:before {
                    content: "";

                    position: absolute;
                    top: -25px;
                    right: 0px;

                    display: block;

                    width: 25px;
                    height: 25px;

                    background: url("~@/assets/img/SubtractUp.svg") no-repeat 0
                        0;
                }

                &:after {
                    content: "";

                    position: absolute;
                    right: 0px;
                    bottom: -25px;

                    display: block;

                    width: 25px;
                    height: 25px;

                    background: url("~@/assets/img/SubtractDown.svg") no-repeat
                        0 0;
                }
            }
        }
    }

    &__logout {
        padding-top: 30px;

        &__btn {
            padding: 21px 25px;
            width: 100%;
            text-align: left;

            font-weight: 500 !important;
            font-size: 18px;
            line-height: 21px;

            color: $white;
            border-color: transparent;
            background-color: transparent;

            & .svg-icon {
                margin-right: 25px;

                fill: $white;
            }

            &:hover {
                color: $white;
                border-color: transparent;
                background-color: transparent;
            }
        }
    }
}
</style>
