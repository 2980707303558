<template>
    <div class="base-header__search">
        <form @submit.stop.prevent="submitSearch">
            <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Поиск"
                v-model="search"
            />
            <button
                type="submit"
                class="btn btn-primary btn-lg"
                style="display: none"
            >
                Искать
            </button>
        </form>
    </div>
</template>

<script>
import { useSearchStore } from "@/store/Search";

export default {
    name: "SearchComponent",
    setup() {
        const store = useSearchStore();
        return { store };
    },
    data() {
        return {
            search: "",
        };
    },
    methods: {
        submitSearch() {
            this.store.getSearchResults(this.search);

            if (this.search.length > 0) {
                this.$router.push({
                    path: "/search",
                    query: { query: this.search },
                });
            }
        },
    },
};
</script>

<style scoped></style>
